body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.swal2-container {
  z-index: 100000 !important;
}

table#contingente tbody tr td {
    border-bottom: solid 1px gray;
}

p.MuiTypography-root{
    font-weight: 600 !important;
}

.blink {
  animation: blink 1s step-start 0s infinite;
  -webkit-animation: blink 1s step-start 0s infinite;
}

.p-3{
  padding:3rem
}
.p-2{
  padding:2rem
}
.p-1{
  padding:1rem
}

@keyframes blink {
  50% {
    opacity: 0.0;
  }
}
@-webkit-keyframes blink {
  50% {
    opacity: 0.0;
  }
}

.phones-special{
  white-space: nowrap;
}


.ml-10{
    margin-left: 10px;
}

.color-yellow{
  color:yellow
}

.color-tomato{
  color:tomato
}